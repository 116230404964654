import { createContext, ReactNode } from 'react';

// project import
import defaultConfig from '../config';
import useLocalStorage from 'hooks/useLocalStorage';

// types
import { PaletteMode } from '@mui/material';
import { CustomizationProps } from 'types/config';

// initial state
const initialState: CustomizationProps = {
    ...defaultConfig,
    onChangeMenuType: () => {},
    onChangeLocale: () => {}
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
    children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
    const [config, setConfig] = useLocalStorage('config', {
        navType: initialState.navType,
        locale: initialState.locale
    });

    const onChangeMenuType = (navType: PaletteMode) => {
        setConfig({
            ...config,
            navType
        });
    };

    const onChangeLocale = (locale: string) => {
        setConfig({
            ...config,
            locale
        });
    };

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeMenuType,
                onChangeLocale
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

export { ConfigProvider, ConfigContext };
