// third-party
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { HashHistory } from 'history';

// project imports
import { reducer as oidcReducer } from 'redux-oidc';
import accountReducer from './accountReducer';
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = (history: HashHistory) =>
    combineReducers({
        oidc: oidcReducer,
        account: accountReducer,
        router: connectRouter(history),
        snackbar: snackbarReducer,
        menu: menuReducer
    });

export default reducer;
