import axios from 'utils/axios';

class DashboardService {
    async getPowerDashboard(body: any): Promise<any> {
        try {
            const result = await axios.post<any>('/PowerDashboard', body);
            return result.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch dashboard');
        }
    }
}

export default DashboardService;
