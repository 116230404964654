// project import

// types
import { NavItemType } from 'types';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [pages]
};

export default menuItems;
