const getGreetingByTime = (): string => {
    const currentUTC = new Date().getUTCHours();

    if (currentUTC >= 5 && currentUTC < 12) {
        return 'GOOD_MORNING';
    }

    if (currentUTC >= 12 && currentUTC < 18) {
        return 'GOOD_AFTERNOON';
    }

    return 'GOOD_EVENING';
};

export default getGreetingByTime;
