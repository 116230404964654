import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    PaletteMode,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';

// assets
import { IconLogout, IconSettings, IconUser } from '@tabler/icons';
import getGreetingByTime from 'utils/date';
import { useTranslation } from 'react-i18next';
import useConfig from 'hooks/useConfig';
import UserService from 'services/UserService';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    // const navigate = useNavigate();
    const { t } = useTranslation();
    const userService = new UserService();

    const { navType, onChangeMenuType } = useConfig();

    const [selectedIndex] = useState(-1);
    const { user, logout } = useAuth();
    const [open, setOpen] = useState(false);
    const { locale } = useConfig();
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    // const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
    //     setSelectedIndex(index);
    //     handleClose(event);

    //     if (route && route !== '') {
    //         navigate(route);
    //     }
    // };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleChange = async (event: SelectChangeEvent) => {
        await userService.setTenant(event.target.value);
        window.location.reload();
    };

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                // icon={
                //     <Avatar
                //         src={User1}
                //         sx={{
                //             ...theme.typography.mediumAvatar,
                //             margin: '8px 0 8px 8px !important',
                //             cursor: 'pointer'
                //         }}
                //         ref={anchorRef}
                //         aria-controls={open ? 'menu-list-grow' : undefined}
                //         aria-haspopup="true"
                //         color="inherit"
                //     />
                // }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                sx={{ pr: 2.5 }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleClose}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack sx={{ pb: 1 }}>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="h4">{t(getGreetingByTime())},</Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {/* {user?.name} */}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">{user?.name}</Typography>
                                            </Stack>
                                            <Divider />
                                        </Box>
                                        <Box sx={{ p: 2 }}>
                                            <FormControl fullWidth>
                                                <InputLabel>Tenant</InputLabel>
                                                <Select
                                                    label="Tenant"
                                                    onChange={handleChange}
                                                    defaultValue={user?.tenantId}
                                                    onClick={(event) => event.stopPropagation()}
                                                >
                                                    {user?.tenants.map((item) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                    <Typography variant="subtitle2">Mode</Typography>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup
                                                            row
                                                            aria-label="layout"
                                                            value={navType}
                                                            onChange={async (e) => {
                                                                onChangeMenuType(e.target.value as PaletteMode);
                                                                await userService.setUserConfig(
                                                                    user?.tenantId!,
                                                                    locale.toLowerCase(),
                                                                    e.target.value == 'dark'
                                                                );
                                                            }}
                                                            name="row-radio-buttons-group"
                                                        >
                                                            <FormControlLabel
                                                                value="light"
                                                                control={<Radio />}
                                                                label="Light"
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': { fontSize: 28 },
                                                                    '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                                                }}
                                                            />
                                                            <FormControlLabel
                                                                value="dark"
                                                                control={<Radio />}
                                                                label="Dark"
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': { fontSize: 28 },
                                                                    '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                                                }}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                                <Divider />
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%'
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5
                                                        }
                                                    }}
                                                >
                                                    <ListItemButton
                                                        sx={{ borderRadius: '8px' }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">{t('LOGOUT')}</Typography>} />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                )}
                            </Paper>
                        </ClickAwayListener>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
