import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import AssetList from 'views/pages/assets/assetsList';
import HalogList from 'views/pages/halog/halogList';
import Error from 'views/pages/maintenance/Error';
import Dashboard from 'views/dashboard/Dashboard';
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/assets',
            element: <AssetList />,
            role: 'User'
        },
        {
            path: '/halogs',
            element: <HalogList />,
            role: 'Sys_Admin'
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/not_found',
            element: <Error />,
            role: 'User'
        },
        {
            path: '*',
            element: <Error />,
            role: 'User'
        }
    ]
};

export default MainRoutes;
