import Halog from 'types/halog';
import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Utilities from 'utils/utilities';

interface ChildComponentProps {
    halog?: Halog;
    onSave(halog: Halog): any;
    onDelete(halog: Halog): any;
    onClose(): any;
}

const HalogEdit: FC<ChildComponentProps> = (props) => {
    const { halog, onSave, onDelete, onClose } = props;
    const [open, setOpen] = useState(false);
    const [halogCopy, setHalogCopy] = useState(halog);
    const theme = useTheme();
    const { t } = useTranslation();
    const [dialogDelete, setDialogDelete] = useState(false);

    useEffect(() => {
        if (halog === undefined) setOpen(false);
        else {
            setOpen(true);
            setHalogCopy(halog);
        }
    }, [halog]);

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('EDIT_DEVICE')} {halog?.name}
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '10px!important' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={halog?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...halogCopy!,
                                            name: d.target.value
                                        };
                                        setHalogCopy(e);
                                    }}
                                    label={t('NAME')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid item>
                            <Button
                                style={{
                                    backgroundColor: theme.palette.error.main,
                                    borderRadius: 7,
                                    border: '2px solid',
                                    borderColor: theme.palette.error.main
                                }}
                                onClick={() => setDialogDelete(true)}
                            >
                                <Typography sx={{ color: 'white' }}> {t('DELETE')}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={onClose}
                                autoFocus
                                style={{
                                    border: '2px solid',
                                    borderColor: theme.palette.secondary.main,
                                    borderRadius: 7,
                                    marginRight: 10
                                }}
                            >
                                {t('CANCEL')}
                            </Button>
                            <Button
                                onClick={() => onSave(halogCopy!)}
                                style={{
                                    border: '4px solid',
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: 7,
                                    background: theme.palette.primary.main
                                }}
                            >
                                <Typography sx={{ color: '#fff' }}> {t('SAVE')}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Dialog
                open={dialogDelete}
                onClose={() => setDialogDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('CONFIRM_DELETE')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t('ARE_YOU_SURE_TO_DELETE')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogDelete(false)}
                        sx={{
                            border: '2px solid',
                            borderColor: theme.palette.secondary.main,
                            borderRadius: 7
                        }}
                    >
                        {t('NO')}
                    </Button>
                    <Button
                        autoFocus
                        sx={{
                            border: '2px solid',
                            borderColor: theme.palette.primary.main,
                            borderRadius: 7,
                            background: theme.palette.primary.main
                        }}
                        onClick={() => {
                            setDialogDelete(false);
                            onDelete(halog!);
                        }}
                    >
                        <Typography sx={{ color: '#fff' }}> {t('YES')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default HalogEdit;
