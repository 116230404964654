import SelectOption from './selectOption';
import { TFunction } from 'i18next';

const getFrequencyConf = (t: TFunction): SelectOption[] => [
    { value: 2, label: `2 ${t('MINUTES')}` },
    { value: 5, label: `5 ${t('MINUTES')}` },
    { value: 10, label: `10 ${t('MINUTES')}` },
    { value: 20, label: `20 ${t('MINUTES')}` },
    { value: 60, label: `60 ${t('MINUTES')}` },
    { value: 120, label: `2 ${t('HOURS')}` },
    { value: 240, label: `4 ${t('HOURS')}` }
];

export default getFrequencyConf;
