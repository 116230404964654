import { IconList } from '@tabler/icons';

// constant
const icons = {
    IconList
};

const pages = {
    id: 'main',
    type: 'group',
    children: [
        {
            id: 'asset',
            title: 'ASSETS',
            type: 'item',
            url: '/assets',
            icon: icons.IconList,
            breadcrumbs: false,
            role: 'User'
        },
        {
            id: 'halog',
            title: 'DEVICES',
            type: 'item',
            url: '/halogs',
            icon: () => '',
            breadcrumbs: false,
            role: 'Sys_Admin'
        }
    ]
};

export default pages;
