import SelectOption from './selectOption';

const accelerationConf: SelectOption[] = [
    { value: 2, label: '2G' },
    { value: 3, label: '3G' },
    { value: 4, label: '4G' },
    { value: 5, label: '5G' },
    { value: 6, label: '6G' },
    { value: 7, label: '7G' },
    { value: 8, label: '8G' },
    { value: 9, label: '9G' },
    { value: 10, label: '10G' }
];

export default accelerationConf;
