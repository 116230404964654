import { useContext, useEffect } from 'react';

// projects import
import useAuth from './useAuth';
import PowerbiContext from 'contexts/PowerbiContext';
import config from '../config';
import DashboardService from 'services/DashboardService';

// ==============================|| POWER BI HOOKS ||============================== //

const usePowerbi = () => {
    const { user } = useAuth();
    const context = useContext(PowerbiContext);

    if (!context) throw new Error('context must be use inside provider');

    const handleLoaded = () => {};
    const handleRendered = () => {};
    const handleError = () => {};

    const eventHandlers = new Map([
        ['loaded', handleLoaded],
        ['rendered', handleRendered],
        ['error', handleError]
    ]);

    const powerbiService = new DashboardService();

    useEffect(() => {
        (async () => {
            if (user) {
                const reportConfig = await powerbiService.getPowerDashboard({
                    reportId: config.powerBiConfig.reportId,
                    workspaceId: config.powerBiConfig.workspaceId,
                    tenantId: user?.tenantId
                });
                context.onChangePowerbiConfig({
                    embedUrl: reportConfig.embedUrl,
                    accessToken: reportConfig.embedToken.token,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        }
                    }
                });
            }
        })();
        return () => context.resetPowerbi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return { powerbiConfig: context.powerbiConfig, eventHandlers, onChangePowerbiConfig: context.onChangePowerbiConfig };
};

export default usePowerbi;
