import Halog from 'types/halog';
import { FC, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Typography,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ChildComponentProps {
    halog?: Halog;
    onAdd(halog: Halog): any;
    onClose(): any;
}

const HalogAdd: FC<ChildComponentProps> = (props) => {
    const { halog, onAdd, onClose } = props;
    const [open, setOpen] = useState(false);
    const [halogCopy, setHalogCopy] = useState(halog);
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if (halog === undefined) setOpen(false);
        else {
            setOpen(true);
        }
    }, [halog]);

    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"> {t('ADD_NEW_DEVICE')} </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: 2, mt: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel> {t('NAME')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={halog?.name}
                                    onChange={(d) => {
                                        const e = {
                                            ...halogCopy!,
                                            name: d.target.value
                                        };
                                        setHalogCopy(e);
                                    }}
                                    type="text"
                                    label="Name"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel> {t('IDENTIFIER')}</InputLabel>
                                <OutlinedInput
                                    defaultValue={halog?.identifier}
                                    onChange={(d) => {
                                        const e = {
                                            ...halogCopy!,
                                            identifier: d.target.value
                                        };
                                        setHalogCopy(e);
                                    }}
                                    type="text"
                                    label="Identifier"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        autoFocus
                        style={{
                            border: '2px solid',
                            borderColor: theme.palette.secondary.main,
                            borderRadius: 7
                        }}
                    >
                        {t('CANCEL')}{' '}
                    </Button>
                    <Button
                        style={{
                            border: '4px solid',
                            borderColor: theme.palette.primary.main,
                            borderRadius: 7,
                            background: theme.palette.primary.main
                        }}
                        onClick={() => onAdd(halogCopy!)}
                    >
                        <Typography style={{ color: '#fff' }}>{t('ADD')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default HalogAdd;
