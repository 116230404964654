import { models } from 'powerbi-client';
// ==============================|| POWER BI ||============================== //

export type PowerbiConfiguration = {
    reportId: string;
    workspaceId: string;
};

export type PowerbiConfigType = models.IReportEmbedConfiguration;

export const initialPowerbiConfig: PowerbiConfigType = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: { layoutType: models.LayoutType.MobilePortrait }
};
interface PowerbiContextType {
    powerbiConfig: PowerbiConfigType;
    onChangePowerbiConfig: (config: models.IReportEmbedConfiguration) => void;
    resetPowerbi: () => void;
}

export default PowerbiContextType;
