import { CSSProperties, FC } from 'react';

import logo from 'assets/images/halog-logo-color.png';

interface LogoProps {
    sx?: CSSProperties;
}

const Logo: FC<LogoProps> = (props) => {
    const { sx } = props;

    if (logo) return <img src={logo} alt="Halog" width="150" />;

    return <img src={logo} alt="Halog" width="200" style={sx} />;
};
export default Logo;
