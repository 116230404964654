import config from '../config';
import axios, { AxiosError } from 'axios';

export const DEFAULT_TIMEOUT = 600000; // in milliseconds

const baseURL = config.serviceBaseUrl;

const axiosInstance = axios.create({
    baseURL,
    timeout: DEFAULT_TIMEOUT
});

axiosInstance.interceptors.request.use(
    (req) => {
        req.headers['Accept-Language'] = 'it-IT';
        return req;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Error on the request')
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.isAxiosError) {
            window.location.replace('/#/error');
            return Promise.reject(error.isAxiosError || `Error: ${error.message}`);
        }
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    console.error('Error 401:', error.message);
                    window.location.replace('/#/under-construction');
                    return Promise.reject((error.response && error.response.data) || `Error 401: ${error.response.data}`);
                case 500:
                    console.error('Error 500: ', error.message);
                    window.location.replace('/#/under-construction');
                    return Promise.reject((error.response && error.response.data) || `Error 500: ${error.response.data}`);
                default:
                    window.location.replace('/#/error');
                    return Promise.reject((error.response && error.response.data) || `Error: ${error.response.data}`);
            }
        }
        if (error.request) {
            if (error.code === 'ECONNABORTED' && error.config && error.config.timeout) {
                // La richiesta abortita per superamento del timeout
                if (error.config.timeoutErrorMessage) {
                    // const newTimeout = error.config.timeout + timeout;
                    // error.config.timeout = newTimeout;
                    // return axiosInstance(error.config);
                }
            }
        }
        return Promise.reject(error || 'Error on the request');
    }
);

// axiosRetry(axiosInstance, {
//     retries: 3,
//     shouldResetTimeout: true,
//     retryDelay: axiosRetry.exponentialDelay,
//     retryCondition: (error: AxiosError) => {
//         return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED';
//     }
// });

export const axiosIsCancel = (error: AxiosError) => axios.isCancel(error);

export default axiosInstance;
