import * as React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Drawer,
    FormControl,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    debounce,
    Button,
    SelectChangeEvent,
    Divider
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
// import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import AddIcon from '@mui/icons-material/Add';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { EnhancedTableHeadProps, HeadCell, ArrangementOrder } from 'types';
import Asset from 'types/asset';
import AssetService from 'services/AssetService';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import AssetEdit from './assetEdit';
import AssetAdd from './assetAdd';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import SkeletonBody from 'ui-component/skeleton/SkeletonBody';
import { useSnackbar } from 'notistack';
import AssetListMobile from './assetListMobile';
import useConfig from 'hooks/useConfig';
import Utilities from 'utils/utilities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AssetFilter from 'types/assetFilter';
import { startOfDay } from 'date-fns/startOfDay';
import { endOfDay } from 'date-fns/endOfDay';
import ShowDevice from 'types/enums/showDevices';
import StepperDatePicker from 'ui-component/StepperDatePicker';
import { SnackbarErrorMessage, SnackbarSuccessMessage } from 'types/SnackbarMessagges';
// table header options
const headCells: HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        label: 'ASSET_NAME',
        align: 'left'
    },
    {
        id: 'halogName',
        numeric: false,
        label: 'DEVICE_NAME',
        align: 'left'
    },
    {
        id: 'halogPaired',
        numeric: false,
        label: 'PAIRED_STATUS',
        align: 'center'
    },
    {
        id: 'halogPairDate',
        numeric: false,
        label: 'PAIR_DATE',
        align: 'right'
    }
];

// ==============================|| TABLE HEADER ||============================== //

interface AssetListEnhancedTableHeadProps extends EnhancedTableHeadProps {
    selected: string[];
}

function EnhancedTableHead({
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selected
}: AssetListEnhancedTableHeadProps) {
    // const createSortHandler = (property: string) => (event: React.SyntheticEvent<Element, Event>) => {
    //     onRequestSort(event, property);
    // };
    const { t } = useTranslation();
    const currentTheme = useTheme();
    const mobileDevice = useMediaQuery(currentTheme.breakpoints.down('md'));
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox" sx={{ pl: 3 }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell> */}
                {/* {numSelected > 0 && (
                    <TableCell padding="none" colSpan={6}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </TableCell>
                )} */}
                {!mobileDevice &&
                    headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {t(headCell.label)}
                        </TableCell>
                    ))}
                {numSelected <= 0 && (
                    <TableCell
                        sortDirection={false}
                        align="right"
                        sx={{ paddingRight: windowWidth < 1400 ? '1.5%!important' : '2%!important' }}
                    >
                        {t('ACTIONS')}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

// const EnhancedTableToolbar = ({ numSelected }: EnhancedTableToolbarProps) => (
//     <Toolbar
//         sx={{
//             p: 0,
//             pl: 1,
//             pr: 1,
//             ...(numSelected > 0 && {
//                 color: (theme) => theme.palette.secondary.main
//             })
//         }}
//     >
//         {numSelected > 0 ? (
//             <Typography color="inherit" variant="h4">
//                 {numSelected} Selected
//             </Typography>
//         ) : (
//             <Typography variant="h6" id="tableTitle">
//                 Nutrition
//             </Typography>
//         )}
//         <Box sx={{ flexGrow: 1 }} />
//         {/* {numSelected > 0 && (
//             <Tooltip title="Delete">
//                 <IconButton size="large">
//                     <DeleteIcon fontSize="small" />
//                 </IconButton>
//             </Tooltip>
//         )} */}
//     </Toolbar>
// );

// ==============================|| ASSET LIST ||============================== //

const AssetList = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { t } = useTranslation();
    const { locale } = useConfig();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
    const { enqueueSnackbar } = useSnackbar();
    const utilities = new Utilities();

    const [order, setOrder] = useState<ArrangementOrder>('asc');
    const [orderBy, setOrderBy] = useState<string>('name');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [searchText, setSearchText] = useState<string>('');
    const [rows, setRows] = useState<Asset[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [assetToEdit, setAssetToEdit] = useState<Asset | undefined>(undefined);
    const [assetToAdd, setAssetToAdd] = useState<Asset | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [pairedAsset, setPairedAsset] = useState<ShowDevice>(ShowDevice.ONLY_PAIRED);
    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);
    const [fliterDialogModalOpen, setFliterDialogModalOpen] = useState(false);
    const enumValues = Object.keys(ShowDevice).filter((key) => isNaN(Number(key)));

    useEffect(() => {
        (async () => {
            if (user) {
                const assetFilter = getAssetFilter();
                await fetchRow(assetFilter);
            }
        })();
    }, [user, page, rowsPerPage, pairedAsset, dateFrom, dateTo, order, orderBy, searchText]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    }, []);

    const fetchRow = useCallback(
        debounce(async (assetFilter: AssetFilter) => {
            setIsLoading(true);
            const paginatedAssets = await AssetService.getAssets(assetFilter);
            setIsLoading(false);
            setRows(paginatedAssets.items);
            setTotalElements(paginatedAssets.totalCount);
        }, 700),
        []
    );

    const getAssetFilter = () => {
        const assetFilter: AssetFilter = {
            dateFrom: dateFrom ? startOfDay(new Date(dateFrom!)) : null,
            dateTo: dateTo ? endOfDay(new Date(dateTo!)) : null,
            orderAscending: order?.toString() === 'asc',
            orderBy: orderBy,
            page: page + 1,
            paired: pairedAsset,
            recordsPerPage: rowsPerPage,
            searchString: searchText
        };
        return assetFilter;
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const newString = event?.target.value;
        setSearchText(newString || '');
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedId = rows.map((n) => n.name);
            setSelected(newSelectedId);
            return;
        }
        setSelected([]);
    };

    // const handleClick = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, name: string) => {
    //     const selectedIndex = selected.indexOf(name);
    //     let newSelected: string[] = [];

    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, name);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    //     }

    //     setSelected(newSelected);
    // };

    const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const onAdd = async (asset: Asset) => {
        if (asset.name) {
            setAssetToAdd(undefined);
            if (user) {
                const result = await AssetService.addAsset(asset, user!.tenantId);
                if (result) {
                    const assetFilter = getAssetFilter();
                    await fetchRow(assetFilter);
                    enqueueSnackbar(t('ASSET_ADDED_SUCCESSFULLY'), SnackbarSuccessMessage);
                } else {
                    enqueueSnackbar(t('ERROR_DURING_ADD_ASSET'), SnackbarErrorMessage);
                }
            }
        } else {
            enqueueSnackbar(t('NAME_IS_MANDATORY'), SnackbarErrorMessage);
        }
    };

    const onSave = async (asset: Asset) => {
        if (asset.name) {
            setAssetToEdit(undefined);
            const result = await AssetService.editAsset(asset);
            if (result) {
                const assetFilter = getAssetFilter();
                await fetchRow(assetFilter);
                enqueueSnackbar(t('ASSET_EDITED_SUCCESSFULLY'), SnackbarSuccessMessage);
            } else {
                enqueueSnackbar(t('ERROR_DURING_EDIT_ASSET'), SnackbarErrorMessage);
            }
        } else {
            enqueueSnackbar(t('NAME_IS_MANDATORY'), SnackbarErrorMessage);
        }
    };

    const onDelete = async (asset: Asset) => {
        setAssetToEdit(undefined);
        const result = await AssetService.deleteAsset(asset.id);
        if (result) {
            const assetFilter = getAssetFilter();
            await fetchRow(assetFilter);
            enqueueSnackbar(t('ASSET_DELETED_SUCCESSFULLY'), SnackbarSuccessMessage);
        } else {
            enqueueSnackbar(t('ERROR_DURING_DELETE_ASSET'), SnackbarErrorMessage);
        }
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleChangeOrder = (e: any) => {
        const { value } = utilities.getFormValue(e);
        setOrderBy(value);
    };

    const handleChangeOrderMethod = (e: any) => {
        const { value } = utilities.getFormValue(e);
        setOrder(value === 'asc' ? 'asc' : 'desc');
    };

    const handleChangeShowDevice = (event: SelectChangeEvent<typeof ShowDevice[keyof typeof ShowDevice]>) => {
        const selectedValue = event.target.value as keyof typeof ShowDevice;
        setPairedAsset(ShowDevice[selectedValue]);
    };

    const setDefaultFilters = () => {
        setOrder('asc');
        setOrderBy('name');
        setDateFrom(null);
        setDateTo(null);
        setPairedAsset(ShowDevice.ONLY_PAIRED);
    };

    return (
        <MainCard title="Assets" content={false}>
            <CardContent>
                <Grid container justifyContent="space-between" sx={{ mb: '1vh' }}>
                    <Drawer
                        anchor="right"
                        open={fliterDialogModalOpen}
                        onClose={() => setFliterDialogModalOpen(false)}
                        PaperProps={{
                            sx: { width: { xs: '75%', md: '50%', lg: '25%' } }
                        }}
                    >
                        <Grid container direction="column" spacing={2} sx={{ p: '15px' }}>
                            <Grid item justifyContent="center" textAlign="center">
                                <Grid container direction="row" alignItems="center">
                                    <Typography textAlign="center" variant="h5">
                                        {t('ADVANCED_FILTERS')}
                                    </Typography>
                                    <Tooltip title={t('CLOSE')}>
                                        <IconButton
                                            sx={{ marginLeft: 'auto', p: 0 }}
                                            onClick={() => {
                                                setFliterDialogModalOpen(false);
                                            }}
                                        >
                                            <HighlightOffIcon color="error" fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FormGroup>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>{t('PAIRED_STATUS')}</InputLabel>
                                        <Select
                                            label={t('PAIRED_STATUS')}
                                            onChange={handleChangeShowDevice}
                                            value={ShowDevice[pairedAsset]}
                                        >
                                            {enumValues.map((key) => (
                                                <MenuItem value={key} key={key}>
                                                    {t(key)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            {(pairedAsset === ShowDevice.ONLY_PAIRED || pairedAsset === ShowDevice.ALL) && (
                                <Grid item>
                                    <StepperDatePicker
                                        initialDate={dateFrom}
                                        finalDate={dateTo}
                                        onChange={(newValue) => {
                                            setDateFrom(newValue.initialDate);
                                            setDateTo(newValue.finalDate);
                                        }}
                                    />
                                </Grid>
                            )}
                            <Divider sx={{ mb: 1, mt: 2, mx: '16px' }} />
                            <Grid item>
                                <FormControl fullWidth size="small">
                                    <InputLabel>{t('ORDER_BY')}</InputLabel>
                                    <Select label={t('ORDER_BY')} name="orderFieldName" onChange={handleChangeOrder} value={orderBy}>
                                        <MenuItem value="name">{t('ASSET_NAME')}</MenuItem>
                                        <MenuItem value="halogName">{t('DEVICE_NAME')}</MenuItem>
                                        <MenuItem value="halogPairDate">{t('PAIR_DATE')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth size="small">
                                    <InputLabel>{t('ORDER_METHOD')}</InputLabel>
                                    <Select
                                        label={t('ORDER_METHOD')}
                                        name="isDescending"
                                        onChange={handleChangeOrderMethod}
                                        sx={{ color: 'white' }}
                                        value={order}
                                    >
                                        <MenuItem value="asc">{t('ASCENDING')}</MenuItem>
                                        <MenuItem value="desc">{t('DESCENDING')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Button
                                    startIcon={<CloseIcon fontSize="large" />}
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                        setDefaultFilters();
                                        setFliterDialogModalOpen(false);
                                    }}
                                >
                                    {t('REMOVE_FILTERS')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Drawer>

                    <Grid item lg={12} xs={12} container>
                        <Grid lg={4} md={4} item xs={6}>
                            <TextField
                                fullWidth
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={handleSearch}
                                placeholder={mobileDevice ? t('SEARCH') : t('SEARCH_ASSET')}
                                value={searchText}
                            />
                        </Grid>
                        <Grid lg={4} md={4} item xs={2} zeroMinWidth />
                        <Grid
                            lg={4}
                            md={4}
                            xs={4}
                            item
                            sx={{ display: 'flex', justifyContent: mobileDevice ? 'space-around' : 'flex-end' }}
                        >
                            <Grid
                                item
                                lg={2}
                                md={2}
                                xs={windowWidth < 400 ? 3 : 4}
                                sx={{
                                    display: 'flex',
                                    justifyContent: windowWidth < 400 ? 'flex-start' : 'flex-end',
                                    textAlign: 'right'
                                }}
                            >
                                <Stack direction="row" spacing={-1}>
                                    <Tooltip title={t('FILTERS')}>
                                        <IconButton onClick={() => setFliterDialogModalOpen(true)}>
                                            <FilterAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: 'right', paddingTop: 0.9 }}>
                                <Tooltip title={t('ADD_ASSET')}>
                                    <IconButton
                                        className="addButton"
                                        sx={{ backgroundColor: theme.palette.primary.main }}
                                        size="small"
                                        onClick={() =>
                                            setAssetToAdd({
                                                id: '',
                                                name: '',
                                                halogName: '',
                                                halogPairDate: ''
                                            })
                                        }
                                    >
                                        <AddIcon sx={{ color: '#fff', fontSize: '17px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {/* table */}
            {rows ? (
                <>
                    {mobileDevice ? (
                        <AssetListMobile assetsList={rows} onEdit={(row) => setAssetToEdit(row)} />
                    ) : (
                        <TableContainer sx={{ pl: '2px', pr: '2px' }}>
                            <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 360px)', overflowX: 'hidden' }}>
                                <Table
                                    stickyHeader
                                    sx={{
                                        minWidth: 750,
                                        '& .MuiTableCell-root': { padding: '2px 8px' }
                                    }}
                                    aria-labelledby="tableTitle"
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        selected={selected}
                                    />
                                    {!isLoading ? (
                                        <TableBody>
                                            {rows.map((row, index) => {
                                                if (typeof row === 'number') return null;
                                                const isItemSelected = isSelected(row.name);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                    >
                                                        {/* <TableCell padding="checkbox" sx={{ pl: 3 }} onClick={(event) => handleClick(event, row.name)}>
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId
                                                                }}
                                                            />
                                                        </TableCell> */}
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            // onClick={(event) => handleClick(event, row.name)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <Typography
                                                                variant="subtitle1"
                                                                sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                            >
                                                                {row.name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            // onClick={(event) => handleClick(event, row.name)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <Typography
                                                                variant="subtitle1"
                                                                sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                                            >
                                                                {row.halogName ? row.halogName : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            // onClick={(event) => handleClick(event, row.name)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <Typography
                                                                variant="subtitle1"
                                                                sx={{
                                                                    color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                {row.halogPairDate && (
                                                                    <BluetoothConnectedIcon sx={{ fontSize: '1.3rem' }} color="secondary" />
                                                                )}
                                                                {!row.halogPairDate && (
                                                                    <BluetoothDisabledIcon sx={{ fontSize: '1.3rem' }} color="error" />
                                                                )}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            // onClick={(event) => handleClick(event, row.name)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <Typography
                                                                variant="subtitle1"
                                                                sx={{
                                                                    color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                                                    float: 'right'
                                                                }}
                                                            >
                                                                {row.halogPairDate ? utilities.formatDate(row.halogPairDate, locale) : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            sx={{ pr: 3, '& .MuiTableCell-root': { padding: '4px 8px' } }}
                                                        >
                                                            <Tooltip title={t('EDIT')}>
                                                                <IconButton
                                                                    color="secondary"
                                                                    size="large"
                                                                    onClick={() => setAssetToEdit(row)}
                                                                >
                                                                    <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={t('VIEW_DATA')}>
                                                                <IconButton
                                                                    color="secondary"
                                                                    size="large"
                                                                    onClick={() => navigate(`/dashboard?id=${row.id}`)}
                                                                >
                                                                    <DashboardIcon sx={{ fontSize: '1.3rem' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    ) : (
                                        <SkeletonBody numHeaders={4} showAction />
                                    )}
                                </Table>
                            </PerfectScrollbar>
                        </TableContainer>
                    )}
                </>
            ) : (
                <Skeleton variant="rectangular" width={210} height={118} />
            )}
            {/* table pagination */}
            <TablePagination
                sx={{
                    ...(mobileDevice && {
                        display: 'flex',
                        '& .MuiTablePagination-actions': {
                            marginLeft: 0
                        },
                        '& .MuiInputBase-root': {
                            marginRight: 1
                        },
                        '& .MuiIconButton-root': {
                            padding: '6px'
                        }
                    })
                }}
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('ROWS_PER_PAGE')}
            />
            <AssetEdit asset={assetToEdit} onSave={onSave} onDelete={onDelete} onClose={() => setAssetToEdit(undefined)} />
            <AssetAdd asset={assetToAdd} onAdd={onAdd} onClose={() => setAssetToAdd(undefined)} />
        </MainCard>
    );
};

export default AssetList;
