import { OptionsObject } from 'notistack';

const defaultSnackbarConfig: OptionsObject = {
    anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
    },
    variant: 'info',
    autoHideDuration: 4000
};

export const SnackbarErrorMessage: OptionsObject = {
    anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
    },
    variant: 'error',
    autoHideDuration: 6000
};

export const SnackbarSuccessMessage: OptionsObject = {
    anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
    },
    variant: 'success',
    autoHideDuration: 6000
};

export default defaultSnackbarConfig;
