import React, { useEffect } from 'react';

// third-party
import useConfig from 'hooks/useConfig';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// load locales files
import translationEN from 'utils/locales/en.json';
import translationIT from 'utils/locales/it.json';

// ==============================|| LOCALIZATION ||============================== //

interface LocalsProps {
    children: React.ReactElement;
}

const resources = {
    en: {
        translation: translationEN
    },
    it: {
        translation: translationIT
    }
};

i18next.use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

const Locales = ({ children }: LocalsProps) => {
    const { locale } = useConfig();

    useEffect(() => {
        i18next.init({
            lng: locale
        });
    }, [locale]);

    return children;
};

export default Locales;
