import { UserInfo } from 'types/userInfo';

const SET_ISINITIALIZED = 'SET_LOADING_STATE';
const SET_USERINFO = 'SET_USERINFO';
const SET_INITIALIZATIONERROR = 'SET_INITIALIZATIONERROR';
const SET_OFFLINECONFIGINITIALIZED = 'SET_OFFLINECONFIGINITIALIZED';

// STATE - This defines the type of data maintained in the Redux store.
export interface AppInitState {
    isInitialized: boolean;
    isLoggedIn: boolean;
    userInfo?: UserInfo;
    isOfflineConfigInitialized: boolean;
    ISinitializationError: boolean;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
interface SetUserInfoAction {
    type: typeof SET_USERINFO;
    userInfo: UserInfo;
    isLoggedIn: boolean;
}
interface SetBooleanAction {
    type: typeof SET_INITIALIZATIONERROR | typeof SET_OFFLINECONFIGINITIALIZED | typeof SET_ISINITIALIZED;
    value: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AppInitActionTypes = SetUserInfoAction | SetBooleanAction;

const initialState: AppInitState = {
    isInitialized: false,
    isLoggedIn: false,
    isOfflineConfigInitialized: false,
    ISinitializationError: false
};

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const action = {
    setIsInitialized: (isInitialized: boolean): AppInitActionTypes => ({
        type: SET_ISINITIALIZED,
        value: isInitialized
    }),
    setIsOfflineConfigInitialized: (isOfflineConfigInitialized: boolean): AppInitActionTypes => ({
        type: SET_OFFLINECONFIGINITIALIZED,
        value: isOfflineConfigInitialized
    }),
    setInitializationError: (isError: boolean): AppInitActionTypes => ({
        type: SET_INITIALIZATIONERROR,
        value: isError
    }),
    setUserInfo: (userInfo: UserInfo): AppInitActionTypes => ({
        type: SET_USERINFO,
        userInfo: userInfo,
        isLoggedIn: true
    })
};

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const accountReducer = (state = initialState, cAction: AppInitActionTypes): AppInitState => {
    switch (cAction.type) {
        case SET_ISINITIALIZED:
            return { ...state, isInitialized: cAction.value };
        case SET_INITIALIZATIONERROR:
            return { ...state, ISinitializationError: cAction.value };
        case SET_OFFLINECONFIGINITIALIZED:
            return { ...state, isOfflineConfigInitialized: cAction.value };
        case SET_USERINFO:
            return { ...state, userInfo: cAction.userInfo, isLoggedIn: cAction.isLoggedIn };
        default:
            return state;
    }
};

export default accountReducer;
