import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import darkThemeIcon from 'assets/images/halog-icon-dark-theme.png';
import lightThemeIcon from 'assets/images/halog-icon-light-theme.png';

// project imports
import { useDispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/slices/menu';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// types
import { LinkTarget, NavItemType } from 'types';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';

interface NavItemProps {
    item: NavItemType;
    level: number;
}

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { user } = useAuth();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { openItem } = useSelector((state) => state.menu);

    const Icon = item?.icon!;
    const itemIcon = item?.icon ? (
        <>
            {item.id !== 'halog' && <Icon stroke={1.5} size="1.3rem" />}
            {item.id === 'halog' &&
                (theme.palette.mode === 'dark' ? (
                    <img src={darkThemeIcon} style={{ width: '24px', height: '24px' }} alt="" />
                ) : (
                    <img src={lightThemeIcon} style={{ width: '24px', height: '24px' }} alt="" />
                ))}
        </>
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: openItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: openItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url!} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id: string) => {
        dispatch(activeItem([id]));
        matchesSM && dispatch(openDrawer(false));
    };
    const checkRole = (role: string) => {
        if (user?.isSystemAdmin) {
            return true;
        }
        if (!user?.roles.includes(role) && role !== 'User') {
            return false;
        }
        return true;
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem([item.id!]));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {checkRole(item.role!) && (
                <ListItemButton
                    {...listItemProps}
                    disabled={item.disabled}
                    sx={{
                        borderRadius: '8px',
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                        py: level > 1 ? 1 : 1.25,
                        pl: `${level * 24}px`
                    }}
                    selected={openItem?.findIndex((id) => id === item.id) > -1}
                    onClick={() => itemHandler(item.id!)}
                >
                    <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant={openItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                                {t(item.title as string)}
                            </Typography>
                        }
                        secondary={
                            item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {t(item.caption as string)}
                                </Typography>
                            )
                        }
                    />
                    {item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
            )}
        </>
    );
};

export default NavItem;
