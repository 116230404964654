import React, { createContext } from 'react';

// project imports
import { AuthContextType } from 'types/auth';
import { useSelector, userManager } from 'store';

// ==============================|| AUTH CONTEXT & PROVIDER ||============================== //

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
    const state = useSelector((s) => s.account);

    const logout = async () => {
        try {
            await userManager.signoutRedirect();
            await userManager.removeUser();
        } catch (error) {
            throw new Error('The logout was not successful');
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                user: state.userInfo,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
