import { User } from 'oidc-client';
import { UserInfo } from 'types/userInfo';
import axios from 'utils/axios';

export default class UserService {
    async getUserInfo(user: User): Promise<UserInfo> {
        try {
            // axios.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;

            const res = await axios.post<UserInfo>(`/GetUserInfo`);
            return res.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to fetch user info');
        }
    }

    async setTenant(tenantId: string): Promise<UserInfo> {
        try {
            const res = await axios.post<UserInfo>(`/SetTenant`, { TenantId: tenantId });

            return res.data;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to set new tenant');
        }
    }

    async setUserConfig(tenantId: string, preferredLanguage: string, useDarkTheme: boolean): Promise<boolean> {
        try {
            const result = await axios.post(`/SetUserConfig`, {
                TenantId: tenantId,
                PreferredLanguage: preferredLanguage,
                UseDarkTheme: useDarkTheme
            });
            if (result.status >= 200 && result.status < 300) {
                return true;
            }
            return false;
        } catch (error: any) {
            return Promise.reject((error.response && error.response.data) || 'Failed to set user config');
        }
    }
}
