import React, { createContext, useState } from 'react';

// project imports
import PowerbiContextType, { PowerbiConfigType, initialPowerbiConfig } from 'types/powerbi';

// ==============================|| AUTH CONTEXT & PROVIDER ||============================== //

const PowerbiContext = createContext<PowerbiContextType | null>(null);

export const PowerbiProvider = ({ children }: { children: React.ReactElement }) => {
    const [powerbiConfig, setPowerbiConfig] = useState<PowerbiConfigType>(initialPowerbiConfig);

    const onChangePowerbiConfig = (config: PowerbiConfigType) => {
        setPowerbiConfig(config);
    };

    const resetPowerbi = () => {
        setPowerbiConfig(initialPowerbiConfig);
    };

    return (
        <PowerbiContext.Provider
            value={{
                ...powerbiConfig,
                powerbiConfig,
                onChangePowerbiConfig,
                resetPowerbi
            }}
        >
            {children}
        </PowerbiContext.Provider>
    );
};

export default PowerbiContext;
