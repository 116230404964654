// third-party
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';

// project imports
import rootReducer from './reducer';
import { createHashHistory } from 'history';
import createOidcMiddleware, { createUserManager } from 'redux-oidc';
import thunkMiddleware from 'redux-thunk';
import { RouterState, routerMiddleware } from 'connected-react-router';
import { User } from 'oidc-client';
import { AppInitState } from './accountReducer';
import { MenuProps } from 'types/menu';
import config from '../config';

// ==============================|| REDUX - MAIN STORE ||============================== //
interface OidcState {
    isLoadingUser: boolean;
    user: User;
}

export interface AppState {
    oidc: OidcState;
    router: RouterState;
    appInit: AppInitState;
    menu: MenuProps;
}

const history = createHashHistory();
export const userManager = createUserManager(config.userManagerConfig);

const oidcMiddleware = createOidcMiddleware(userManager);
const allReducers = rootReducer(history);

const middleware = [oidcMiddleware, thunkMiddleware, routerMiddleware(history)];

const store = configureStore({
    reducer: allReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(middleware)
});

export type RootState = ReturnType<typeof allReducers>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, useSelector, useDispatch };
