import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';

interface Props {
    showAction?: boolean;
    numHeaders: number;
}

const SkeletonBody: FC<Props> = (props) => {
    const { showAction, numHeaders } = props;

    const cellsNumber = showAction ? numHeaders + 1 : numHeaders;

    return (
        <TableBody>
            {[...Array(10)].map((_, index) => (
                <TableRow key={index}>
                    {[...Array(cellsNumber)].map((_, index) => (
                        <TableCell key={index}>
                            <Skeleton animation="wave" variant="text" width="100%" />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
};

export default SkeletonBody;
