import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import imageErrorUnauthorized from 'assets/images/maintenance/image-error-unauthorized.svg';
import darkImageErrorUnauthorized from 'assets/images/maintenance/image-error-unauthorized-dark.svg';
import { useTranslation } from 'react-i18next';

// styles
const PageContentWrapper = styled('div')({
    maxWidth: 350,
    margin: '0 auto',
    textAlign: 'center'
});

const ConstructionCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const CardMediaBlock = styled('img')({
    width: '100%',
    maxHeight: 500
});

// ==============================|| ERROR PAGE ||============================== //

const Unauthorized = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <ConstructionCard>
            <CardContent>
                <Grid container justifyContent="center" spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <CardMediaBlock src={theme.palette.mode === 'dark' ? darkImageErrorUnauthorized : imageErrorUnauthorized} />
                    </Grid>
                    <Grid item xs={12}>
                        <PageContentWrapper>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography variant="h1" component="div">
                                        {t('UNAUTHORIZED')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{t('YOU_ARE_NOT_AUTHORIZED')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <AnimateButton>
                                        <Button variant="contained" size="large" onClick={() => navigate('/login')}>
                                            <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} /> Login
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            </Grid>
                        </PageContentWrapper>
                    </Grid>
                </Grid>
            </CardContent>
        </ConstructionCard>
    );
};

export default Unauthorized;
