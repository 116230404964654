import { Link as RouterLink, useLocation } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const location = useLocation();
    return (
        <Link component={RouterLink} to={location.pathname}>
            <Logo />
        </Link>
    );
};

export default LogoSection;
